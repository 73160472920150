.leaflet-container{
    height:100vh;
    width:70vw;
    overflow-y: hidden;
    text-align:center;
    margin: 0 auto;
    border: 5px solid lightgrey;
}
.row-spacers{
    background: #547980;
    padding:30px;
    margin: 2rem 0;
    color:'white';
  }
.vidar-table{
    border-radius:2px ;
}

.vidar-messages th {
    position:sticky;
    top:0;
    background: linear-gradient( rgb(37, 85, 57), rgb(19, 128, 45) );
    width:70vw;
}

#location{
    width:200px;  
}

#rigID{
    width:100%;
    height:30px;
    box-sizing:border-box;
}

body {
    background-color: #47d1da;
}
.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
    
.telemetry-buttons{
    background-color:#45ADA8;
    color:white;
}

@keyframes dots {
    0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
    color: black;
    text-shadow:
    .25em 0 0 rgba(0,0,0,0),
    .5em 0 0 rgba(0,0,0,0);}
    60% {
    text-shadow:
        .25em 0 0 black,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;}}

@keyframes ldio-kb1qnoureh9 {
    0% { opacity: 1 }
    100% { opacity: 0 }
}
.ldio-kb1qnoureh9 div {
    left: 94px;
    top: 48px;
    position: absolute;
    animation: ldio-kb1qnoureh9 linear 1s infinite;
    background: #52c1b3;
    width: 12px;
    height: 24px;
    border-radius: 6px / 12px;
    transform-origin: 6px 52px;
}.ldio-kb1qnoureh9 div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #52c1b3;
}
.loadingio-spinner-spinner-o5154xxwmd {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
}
.ldio-kb1qnoureh9 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.ldio-kb1qnoureh9 div { box-sizing: content-box; }

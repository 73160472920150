.leaflet-container{
    height:100vh;
    width:60vw;
    text-align:center;
    margin: 0 auto;
    border: 5px solid lightgrey;
}

.react-dual-listbox{
  width:78vw;
  padding: 0 5%;
}
.vidar-table{
    border-radius:2px ;
}

.vidar-messages th {
    position:sticky;
    top:0;
    background: linear-gradient( rgb(37, 85, 57), rgb(19, 128, 45) );
}
.listbox-headers{
  text-align:left;
  color:white;
}
.listbox-row-divider{
  background:linear-gradient(to right,grey,lightgrey, white 100%);
  border-radius:5px;
  border:none;
  height:2px;
  margin-right: 50px;
}
#location{
  width:200px;  
}

#rigID{
  width:75%;
  height:30px;
  box-sizing:border-box;
  color:'red';
}
.row-spacer{
  background: #547980;
  padding:20px;
  margin: 1rem 0;
}

.vidar-messages tr td:nth-child(2){
  width:60%;
}

body {
    background-color: #47d1da;
}
.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
.reconstruction-buttons{
  background-color:rgb(93, 93, 184);
  color:white;
}
@keyframes dots {
    0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
    color: black;
    text-shadow:
    .25em 0 0 rgba(0,0,0,0),
    .5em 0 0 rgba(0,0,0,0);}
    60% {
    text-shadow:
        .25em 0 0 black,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;}}

@keyframes ldio-kb1qnoureh9 {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

.ldio-kb1qnoureh9 div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-kb1qnoureh9 linear 1s infinite;
  background: #52c1b3;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-kb1qnoureh9 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #52c1b3;
}.ldio-kb1qnoureh9 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #52c1b3;
}
.loadingio-spinner-spinner-o5154xxwmd {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-kb1qnoureh9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; 
}
.ldio-kb1qnoureh9 div { box-sizing: content-box; }


.react-datepicker__month-container{
  background:#547980;
  color:white;
}
.react-datepicker__header{
  background:#547980;
}
.react-datepicker__day, 
.react-datepicker__month-text, 
.react-datepicker__quarter-text, 
.react-datepicker__year-text{
  background: #547980;
  
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range {
  background-color:red;
}
#react-datepicker__today-button {
  background: red;

}
.react-datepicker__day{
  color:white !important;
}


.react-datepicker__day--keyboard-selected:hover, 
.react-datepicker__month-text--keyboard-selected:hover, 
.react-datepicker__quarter-text--keyboard-selected:hover, 
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #547980 !important;
  color:black !important;
}

.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected{
  background-color:transparent !important;
  font-weight:normal;
}

.react-datepicker__header{
  background-color:#547980 !important;
  color:white;
}

.react-datepicker__current-month, 
.react-datepicker-time__header, 
.react-datepicker-year-header{
  color:white !important;
}

.react-datepicker__day-name, 
.react-datepicker__day, 
.react-datepicker__time-name {
  color:white !important;
}

.react-datepicker__navigation--previous{
  border-right-color:#FFAAA6 !important;
}
.react-datepicker__navigation--next{
  border-left-color:#FFAAA6 !important;
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range{
  background-color: white !important;
  color:black !important;
}

.react-datepicker__day:hover, 
.react-datepicker__month-text:hover, 
.react-datepicker__quarter-text:hover, 
.react-datepicker__year-text:hover{
  color:black !important;
}

.react-datepicker__day--today, 
.react-datepicker__month-text--today, 
.react-datepicker__quarter-text--today, 
.react-datepicker__year-text--today {
  font-weight: normal !important;
  border: none !important;
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range{
  background-color: white !important;
  color:black !important;
}


.remove{
    background-color:tomato;
    color:white;
}

.input-buttons-remove{
    color:white;
    background-color: red;
}

.input-buttons-add{
    color:white;
    background-color: green;
}

.input-buttons-add:active{
    transform: translate(0, 4px)
}
.input-buttons-remove:active{
    transform: translate(0, 4px)
}
*{
  font-family: Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


[data-amplify-theme]{
  --amplify-primary-color: #008000;
  --amplify-primary-tint: #0000FF; 
  --amplify-primary-shade: #008000;
}

[data-amplify-authenticator][data-variation=modal] {
  position: absolute;
}
div[data-amplify-authenticator]{
  max-width:  100%;
}
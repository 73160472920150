thead {
    background-color: rgb(128, 128, 128);
    color: white;
  }
  
  .table {
    table-layout: auto !important;
  }
  
  .react-bootstrap-table-editing-cell input.form-control.editor.edit-text {
    display: inline !important;
    width: 180px !important;
    overflow-wrap: break-word !important;
    height: 100px !important;
    z-index: 3 !important;
    color: black !important;
  }
  
  .driver-table-buttons {
    width: 200px;
    background: lightgreen;
    color: white;
    text-decoration: none !important;
    border-radius: 2px;
    border-color: white;
  }
  
.navBar{
    display:flex;
    flex-direction: column;
    background: transparent;
    overflow-y: visible; 
    background: rgb(169,169,169);
    border-radius: 5px;
    text-align: left;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.8);
    text-decoration: none;
    position:relative;
    width: 300px;
    
    
}
ul {
    list-style-type: None;
    text-align:left;
}
nav{
    height:100%;
    
}
.navLinks{
    text-decoration:none;

}
.navlink{
    color:black;
    text-decoration: none;
}
.card-header:hover{
    background-color: rgb(121, 162, 171,0.5);
    cursor:pointer;
}

.navlink-active{
    background-color: rgb(121, 162, 171,0.5);
    text-decoration:none;
}
.navText{
    display:inline;
    text-decoration: none;
}

.toggle-on-navbar{
    margin:10px;
}
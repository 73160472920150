.topic-headers{
    text-align: left;
    width: 50vw;
    font-weight: bold;
    font-size:300%;
    padding-bottom:5vh;
}
.paragraph-headers{
    text-align:left;
    width:50vw;
    font-weight:bold;
    padding-bottom:3vh;
}
.sub-headers{
    text-align:left;
    width:50vw;
    font-weight:bold;
    padding-bottom:3vh;
}
.document-content{
    text-align:left;
    width:60vw;
}
.document-content .ordered-list{
    text-align:left;
    width:60vw;
}
.safety-photos{
    width:50vw;
    margin-left:10vw;
    box-shadow:0px 8px 16px 0px rgba(0,0,0,0.8)
} 
.sub-topic-headers{
    text-align:left;
    width:70vw;
    padding: 2vw 0;
    font-style: italic;
    font-weight:bold;
}
.fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1500ms ease-out, transform 1000ms ease-out,
    visibility 1500ms ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
.horizontal-row{
    height: 1px;
    width:100%;
    background-color: lightgray;
}
.jumplink-ordered-list{
    text-align: left;
    list-style-type:disc;
}
.jumplink-ordered-list li{
    text-align: left;
    list-style-type: disc;
}
.unordered-list li{
    display:list-item;
    list-style-type: circle;
}
li{
    padding: .2vh 0;
}
.list-bolders{
    font-weight:bold;
}

.caption-paragraphs{
    color:darkgrey;
}
@media(max-width: 800px){
    .card-media-layout{
        flex-wrap: wrap;
        display:inline-block;
        text-align: center;
    }
}
@media(max-width: 800px){
    .card-headers-h2{
        margin-right: 100px;
    }
}
thead {
    background-color: rgb(128, 128, 128);
    color: white;
  }
  
.table {
    table-layout: auto !important;
  }
  
.react-bootstrap-table-editing-cell input.form-control.editor.edit-text {
    display: inline !important;
    width: 180px !important;
    overflow-wrap: break-word !important;
    height: 100px !important;
    z-index: 3 !important;
    color: black !important;
  }
  
.driver-table-buttons {
    width: 150px;
    background: green;
    color: white;
    border-radius: 2px;
    border-color: white;
  }

.driver-table-buttons a{
  background: green;
  color:white;
}

.driver-table-buttons a:hover{
  color:green;
  text-decoration: none;
}

.driver-table-buttons:hover{
  background-color: white;
  border-radius: green;
}

#root > div > div > div.body > div > div:nth-child(3) > div.row.react-bootstrap-table-pagination{
  text-align: left;
}

#pageDropDown{
  background-color: #007bff !important;
  color: white !important;
}

.react-bootstrap-table-page-btns-ul{
  float: left !important;
}
.App {
  text-align: center;
  display:flex;
  flex-direction: row;
  height:100vh;
  width:100%;
  margin:0;
  position:absolute;
}
.sidebar{
  display:none;
}
.body{
  width:100%;
  height:100%;
  right:0;
  overflow:auto;
  transition: left .3s ease-in-out;
}
.menuButton{
  z-index:3;  
  position: absolute; 
  background: #45ADA8;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
}
.menuIcon{
  position: absolute;
  left:10rem;
}

.sidebar.open {
  display:block;
  z-index: 2;
}


button {
  border-radius: 5px;
}
button:hover{
  cursor:pointer;
}
button:active{
  transform: translate3d(0,4px,0)
}

.logOutButton{
  display:none;
}

.logOutButton.open{
  display:inline;
}

amplify-greetings {
	--background-color: None;
  --border-color: transparent;
}

